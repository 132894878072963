<template>
	<div class="Logs">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">运营中心</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">协议文档</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form inline>
					<el-form-item label="名称">
						<el-input size="small" placeholder="请填写协议名称" v-model="searchForm.name"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="frame_content mgtop10" style="min-height: 600px;">
			<div class="wf lineh40">
				<el-button v-if="principal.level==3" type="danger" size="small" @click="batchRemove"><i
						class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newItem"><i class="fa fa-plus"></i>新增协议</el-button>
			</div>

			<el-table class="mgtop10" :data="protocolList" :stripe="true" border
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="名称" prop="name">
					<template slot-scope="scope">
						<a :href="'https://app.sjymbgl.com/'+scope.row.url" target="_blank">{{scope.row.name}}</a>
					</template>
				</el-table-column>
				<el-table-column label="链接" prop="name">
					<template slot-scope="scope">
						{{$http.webConfig.webUrls.app +scope.row.url}}
					</template>
				</el-table-column>
				<el-table-column label="发布时间" prop="updateTime" align="center" width="160px" />
				<el-table-column label="操作" key="action" align="center" width="200px">
					<template slot-scope="scope">
						<el-button type="primary" size="small" @click="showProtocol(scope.$index)"><i
								class="fa fa-edit"></i> 编辑</el-button>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf overflow bgffffff mgtop10">
				<div class="pdding20">
					<el-pagination v-show="protocolList.length>0" background layout="prev, pager, next"
						@current-change="pageChange" :page-size="searchForm.pageSize" :total="searchForm.total"
						class="fr"> </el-pagination>
				</div>
			</div>
		</div>

		<!-- 弹出层 -->
		<fragment-protocol-option ref="protocolOption" @addProtocol="addProtocol"></fragment-protocol-option>
		<!-- 弹出层 end-->


	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import FragmentProtocolOption from '@/components/fragment/news/FragmentProtocolOption.vue'
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		components: {
			VueUEditor,
			FragmentProtocolOption
		},
		data() {
			return {
				imgUploadUrl: this.$http.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				modalMask: false,
				modalzIndex: -1,
				tableLocale: {
					emptyText: '暂无数据'
				},
				dialogFormVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20
				},
				total: 0,
				multipleSelection: [],
				protocolTypeList: [],
				protocolClassificationList: [],
				protocolClassificationCacheList: [],
				protocolList: [],
				mcover: '',
				protocolForm: {},
				loading: false,
				imageUrl: '',
			}
		},
		mounted() {},
		methods: {
			ueditorReady(ueditor) {
				let _this = this
				console.log("编辑器", this.ueditor)
				ueditor.addListener("contentChange", () => {
					console.log("文档内容", ueditor.getContent())
					_this.protocolForm.content = ueditor.getContent();
				});
				ueditor.setContent(this.protocolForm.content)
			},
			dateChange(moment, dateString) {

			},
			menuSelect(item) {
				console.log("菜单" + item.key, item);
				//获取当前类别的分类
				this.curTypeName = this.protocolTypeList[parseInt(item)].name;
				this.curTypeSerial = this.protocolTypeList[parseInt(item)].serial;
				this.searchForm.page = 1;
				this.searchForm.superSerial = this.curTypeSerial;
				this.searchForm.classification = this.curTypeSerial;
				this.searchForm.name = "";
				this.getProtocolList();
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.mcover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.mcover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}

			},
			newItem() {
				this.$refs["protocolOption"].newItem();
			},
			showProtocol(index) {
				this.protocolForm = this.protocolList[index];
				this.$refs["protocolOption"].showItem(this.protocolForm);
			},
			doSearch() {
				//查询
				this.searchForm.page = 1;
				this.getProtocolList();
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getProtocolList()
			},
			getProtocolTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.protocolTypeList, {
					pageSize: 100
				}, false, (res) => {
					this.protocolTypeList = res.data;
					console.log("项目类别已加载完", this.protocolTypeList);
					//获取当前类别的分类
					this.curTypeName = this.protocolTypeList[0].name;
					this.curTypeSerial = this.protocolTypeList[0].serial;
					this.searchForm.superSerial = this.curTypeSerial;
					this.getClassificationList();
				});
			},
			getClassificationList() {
				this.$http.request(this, this.$apis.material.protocolClassificationList, this.searchForm, false, res => {
					this.protocolClassificationList = res.data;
					this.searchForm.total = res.count;
				});
			},
			getProtocolList() {
				this.$http.request(this, this.$apis.material.protocolList, this.searchForm, false, res => {
					this.protocolList = res.data;
					this.total = res.count;
				});
			},
			removeProtocol(index) {
				var protocol = this.protocolList[index];
				this.$confirm('确定要删除协议[' + protocol.name + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.protocolRemove.replace("{serial}", protocol.serial);
					this.$http.request(this, url, {}, true, res => {
						this.protocolList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的协议 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.material.protocolBatchRemove, {
						serials: serials
					}, true, res => {
						this.protocolList = this.protocolList.filter(protocol => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == protocol.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			}
		},
		created() {
			this.getProtocolTypeCacheList();
			this.getProtocolList();
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}
</style>
