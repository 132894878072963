<template>
	<div class="wf">

		<el-dialog :close-on-click-modal="false" title="协议文档信息" :visible.sync="dialogVisible" width="1000px">

			<div class="wf">
				<el-form ref="protocolForm" :model="protocolForm" label-width="120px" :rules="rules">
					<el-row class="wf">
						<el-col :span="24">
							<el-form-item label="协议名称" prop="name">
								<el-input v-model="protocolForm.name" placeholder="请填写文章标题" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label-width="0">
								<div class="pdding10" style="border-top: 1px dashed #DEDEDE;">
									<div class="wf lineh40 bold">协议内容</div>
									<VueUEditor @ready="ueditorReady" style="min-height:600px;background: #F1F1F1;">
									</VueUEditor>
								</div>
							</el-form-item>
						</el-col>

					</el-row>

				</el-form>
			</div>


			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishProtocol">保存</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	export default {
		name: 'Protocol',
		components: {
			VueUEditor
		},
		data() {
			return {
				dialogVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				loading: false,
				tableLocale: {
					emptyText: '暂无数据'
				},
				protocolTypeList: [],
				protocolClassificationList: [],
				cover: '',
				searchForm: {
					page: 1,
					pageSize: 100
				},
				protocolForm: {
					cover: "",
					content: ""
				},
				superSerial: "",
				rules: {
					name: [{
							required: true,
							message: '请填写协议名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 2 到 100个字符',
							trigger: 'blur'
						}
					],
					classification: [{
						required: true,
						message: '请选择所在分类',
						trigger: 'blur'
					}],
					origin: [{
							required: true,
							message: '请填写素材来源',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					digest: [{
							required: true,
							message: '请填写素材简介',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 200,
							message: '长度在 2 到 200个字符',
							trigger: 'blur'
						}
					],
					subTime: [{
						required: true,
						message: '请选择发布时间',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.protocolForm.cover = res.files[0].uploadUrl;
			},
			ueditorReady(ueditor) {
				let _this = this
				this.ueditor = ueditor;
				ueditor.addListener("contentChange", () => {
					_this.protocolForm.content = ueditor.getContent();
				});
				if (this.protocolForm.content != undefined)
					ueditor.setContent(this.protocolForm.content)
			},
			menuSelect(item) {
				this.searchForm.superSerial = item;
				this.searchForm.page = 1;
				this.searchForm.name = "";
				this.getClassificationList();
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.cover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.cover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}
			},
			newItem() {
				this.protocolForm = {
					cover: "",
					subTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
					classification: ""
				};
				console.log("dsadas", this.ueditor)
				if (this.ueditor != undefined)
					this.ueditor.setContent("");
				this.dialogVisible = true;
			},
			showItem(protocolForm) {
				this.dialogVisible = true;
				this.protocolForm = this.$http.cleanData(protocolForm);
				var claszzes = protocolForm.classification.split("-");
				this.superSerial = claszzes[0];
				if (this.protocolForm.content != undefined && this.ueditor != null)
					this.ueditor.setContent(this.protocolForm.content)
			},
			getProtocolTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.protocolTypeList, {
					pageSize: 100
				}, false, res => {
					this.protocolTypeList = res.data;
					if (this.superSerial == "")
						this.superSerial = this.protocolTypeList[0].serial;
					this.getClassificationList();
				});
			},
			getClassificationList() {
				this.protocolForm.classification = "";
				this.$http.request(this, this.$apis.material.protocolClassificationList, {
					superSerial: this.searchForm.superSerial,
					pageSize: 100
				}, false, res => {
					console.log("子分类", res)
					this.protocolClassificationList = res.data;
				});
			},
			publishProtocol() {
				this.$refs['protocolForm'].validate((valid) => {
					if (valid) {
						var protocolForm = Object.assign({}, this.protocolForm);
						var claszzes = protocolForm.classification.split("-");
						protocolForm.classification = claszzes[claszzes.length - 1];
						protocolForm.id = "";
						protocolForm.updateTime = "";

						this.$http.request(this, this.$apis.material.protocolPublish, protocolForm, true, (
						res) => {
							let data = res.data;
							if (data != null) {
								this.$emit("addProtocol", data)
							}
							this.dialogVisible = false;
						});
					}
				});
			}
		},
		watch: {

		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-default .edui-editor-iframeholder {
		height: 514px;
	}

	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>


<style>
</style>
